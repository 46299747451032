import axiosIns from '@/libs/axios';
import moment from 'moment';
import { getUserData} from '@/auth/utils';


 
 
// if(getUserData() != null){
  axiosIns
  .get('companyInfo')
  .then((res) => {
    currencies = res.data.data;
    defaultCurrency = res.data.defaultCurrency;
    companyName = res.data.name;
    streetAddress = res.data.streetAddress;
    town = res.data.town;
    post_code = res.data.post_code;
    country = res.data.country;
    phone = res.data.phone;
    vat = res.data.vat;
  })
  .catch((error) => {
    console.log(error);
  });
// }
 


export let currencies = currencies ? currencies : [];
export let defaultCurrency = defaultCurrency;
export let companyName = companyName;
export let logoLink = 'logo.png';
export let invoiceLink = 'invoice_logo.png';
export let streetAddress = streetAddress;
export let town = town;
export let post_code = post_code;
export let country = country;
export let phone = phone;
export let vat = vat;
export let inputOptions = {
  number: {
    numeral: true,
    numeralPositiveOnly: true,
    numeralThousandsGroupStyle: 'none',
    numeralDecimalMark: 'none',
    delimiter: 'none',
    numeralIntegerScale: 3,
  },

  price: {
    numeral: true,
    numeralPositiveOnly: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalMark: '.',
    delimiter: ',',
    numeralIntegerScale: 9,
  },

  phone: {
    phone: true,
  },

  // creditCard: {
  //   creditCard: true,
  // },
  // date: {
  //   date: true,
  //   delimiter: '-',
  //   datePattern: ['Y', 'm', 'd'],
  // },
  // phone: {
  //   phone: true,
  //   phoneRegionCode: 'US',
  // },
  // time: {
  //   time: true,
  //   timePattern: ['h', 'm', 's'],
  // },
  // number: {
  //   numeral: true,
  //   numeralThousandsGroupStyle: 'thousand',
  // },
  // block: {
  //   blocks: [4, 3, 3],
  //   uppercase: true,
  // },
  // delimiter: {
  //   delimiter: '·',
  //   blocks: [3, 3, 3],
  //   uppercase: true,
  // },
  // customDelimiter: {
  //   delimiters: ['.', '.', '-'],
  //   blocks: [3, 3, 3, 2],
  //   uppercase: true,
  // },
  // prefix: {
  //   prefix: '+63',
  //   blocks: [3, 3, 3, 4],
  //   uppercase: true,
  // },
};

export const func = {
  getName: () => {
    return companyName;
  },
  getCurrencies: () => {
    return currencies;
  },
  getDefaultCurrency: () => {
    return defaultCurrency;
  },
  getLogo: () => {
    return logoLink;
  },
  getInvoiceLogo: () => {
    return invoiceLink;
  },
  getStreet: () => {
    return streetAddress;
  },
  getTown: () => {
    return town;
  },
  getPostCode: () => {
    return post_code;
  },
  getCountry: () => {
    return country;
  },
  getPhone: () => {
    return phone;
  },
  getVat: () => {
    return vat;
  },

  getInputOptions: () => {
    return inputOptions;
  },

  getCurrencyCode: (currencyId) => {
    return currencies.find((a) => a.id == currencyId).code;
  },

  getCurrencySymbol: (currencyId) => {
    return currencies.find((a) => a.id == currencyId).symbol;
  },

  formatPrice(value, currencyId = defaultCurrency) {
    if (value != null) {
      let val = (value / 1).toFixed(2).replace(',', '.');
      let currency = '';

      if (currencies != []) {
        currency = currencies.find((a) => a.id == currencyId).symbol;
        return currency + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
  },

  varyant(val) {
    if (val == 'A') {
      return 'success';
    } else if (val == 'B') {
      return 'info';
    } else if (val == 'C') {
      return 'danger';
    } else if (val == 'D') {
      return 'secondary';
    } else if (val == 'E') {
      return 'warning';
    } else if (val == 'F') {
      return 'primary';
    } else {
      return 'success';
    }
  },

  varicon(val) {
    if (val == 'A') {
      return 'SaveIcon';
    } else if (val == 'B') {
      return 'RefreshCwIcon';
    } else if (val == 'C') {
      return 'XIcon';
    } else if (val == 'D') {
      return 'AlertOctagonIcon';
    } else if (val == 'E') {
      return 'ShareIcon';
    } else if (val == 'F') {
      return 'PrinterIcon';
    } else {
      return 'success';
    }
  },

  dateFormatTimeline(val) {
    return moment(String(val)).format('DD-MM-YYYY HH:mm');
  },
};
