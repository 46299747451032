export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      Permission: 'settings_page_menu',
    },
  },

  {
    path: '/settings/homepage',
    name: 'settings-homepage',
    component: () => import('@/views/settings/SetHomepage/SetHomepage.vue'),

    meta: {
      Permission: 'settings_page_menu',
    },
  },

  {
    path: '/settings/userrole',
    name: 'settings-userrole',
    component: () => import('@/views/settings/Permissions/role-list/RoleList.vue'),

    meta: {
      Permission: 'settings_page_menu',
    },
  },

  {
    path: '/settings/currencies',
    name: 'settings-currencies',
    component: () => import('@/views/settings/SetCurrency/CurrencyList.vue'),

    meta: {
      Permission: 'settings_page_menu',
    },
  },

  
  {
    path: '/settings/general',
    name: 'settings-general',
    component: () => import('@/views/settings/SetGeneral/SetGeneral.vue'),

    meta: {
      Permission: 'settings_page_menu',
    },
  },

];
