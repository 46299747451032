export default [
  {
    path: '/hotel/hotel-list',
    name: 'hotel-list',
    component: () => import('@/views/hotel/hotel-list/HotelList.vue'),
    meta: {
      Permission: 'hotel_page_menu',
    },
  },

  {
    path: '/hotel/hotel-edit/:id',
    name: 'hotel-edit',
    component: () => import('@/views/hotel/hotel-add/HotelEdit.vue'),
    meta: {
      Permission: 'hotel_page_menu',
    },
  },

  {
    path: '/hotel/hotel-add',
    name: 'hotel-add',
    component: () => import('@/views/hotel/hotel-add/HotelAdd.vue'),
    meta: {
      Permission: 'hotel_add_btn',
    },
  },

  {
    path: '/hotel/hotel-view/:id',
    name: 'hotel-view',
    component: () => import('@/views/hotel/hotel-view/HotelView.vue'),
    meta: {
      Permission: 'hotel_page_menu',
    },
  },
];
